import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Container from './component/Container'
import {RecoilRoot} from "recoil";

const habadu = () => {
    window.location = 'https://youtube.com/watch?v=j_MNxdEf4T4'
}
const puinit = () => {
    window.location = 'https://drive.google.com/drive/folders/1kCFrpl2DO0NiaNyIAggSCbn_P3caIN-X'
}

ReactDOM.render(
    <RecoilRoot>
        <Router>
            <Switch>
                <Route path="/habadu" component={habadu}/>
                <Route path="/puinit" component={puinit}/>
                <Route path="/">
                    <Container/>
                </Route>
            </Switch>
        </Router>
    </RecoilRoot>,
    document.getElementById('root')
);